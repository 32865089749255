body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiInputBase-root {
  border-radius: 100px !important;
  border-color: "#58595B";
}

@font-face {
  font-family: "Light"; /*Can be any text*/
  src: local("IpirangaTextos-Light"),
    url("./fonts/IpirangaTextos-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Bold"; /*Can be any text*/
  src: local("IpirangaTextos-Bold"),
    url("./fonts/IpirangaTextos-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Medium"; /*Can be any text*/
  src: local("IpirangaTextos-Medium"),
    url("./fonts/IpirangaTextos-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Bold Titulos"; /*Can be any text*/
  src: local("IpirangaTitulos-Bold"),
    url("./fonts/IpirangaTitulos-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Regular"; /*Can be any text*/
  src: local("IpirangaTextos-Regular"),
    url("./fonts/IpirangaTextos-Regular.ttf") format("truetype");
}

.notistack-Snackbar {
  width: 100vw !important;
  margin: 0 10%;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1400px) {
  .notistack-Snackbar {
    margin: 0 auto;
    width: 1100px !important;
  }
}
