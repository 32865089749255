@import "../../utils/variables.scss";

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 32px;
  padding-left: 8px;
  width: 262px;
  height: 340px;
  border-radius: 8px;

  margin-bottom: 3%;

  gap: 10%;

  cursor: pointer;

  box-shadow: 0px 2px 4px 0px #00000033;

  box-shadow: 0px 1px 10px 0px #0000001f;

  box-shadow: 0px 4px 5px 0px #00000024;
}

.title {
  font-style: normal;
  font-family: Bold;
  font-weight: 700;
  //styleName: Headline 4;
  width: 100%;
  font-size: 30px;

  line-height: 36px;
  letter-spacing: 0px;
  text-align: left;

  color: #fff;
}
