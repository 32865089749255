@import "../../utils/variables.scss";

.searchInput {
  position: relative;
}
.typeahead {
  box-sizing: border-box;
  font-family: "Regular" !important;
  height: 40px;
  width: 100% !important;
  background: $white;
  // border: 1px solid $grey_1;
  border-radius: 2rem !important;
}
