@import "../../utils/variables.scss";

.title {
  font-family: Bold Titulos;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.18px;

  color: $blue_text;
  margin: 7% 0 2% 0;
  width: 100% !important;
}

.header {
  width: 100% !important;
}

.header :nth-child(2) {
  position: absolute;
  right: 3%;
  top: 8%;
}

.modal {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.modal :first-child :first-child {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.areaClassName {
  width: 100% !important;
  display: flex;
  justify-content: space-around;
}

.saveButton {
  background-color: $green_1;
  margin-left: 20px;
  border: 1px solid $green_1;
}

.footer {
  width: 45% !important;
  display: flex;
  justify-content: center;
}
