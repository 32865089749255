@import '../../utils/variables.scss';

.title {
    font-family: Bold Titulos;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.18px;
    color: $blue_text;
    margin: 7% 0 2% 0;
    width: 100% !important;
}

.header {
    width: 100% !important;
}

.header :nth-child(2) {
    position: absolute;
    right: 3%;
    top: 8%;
}

.modal {
    display: flex !important;
    flex: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.modal :first-child :first-child {
    display: flex;
    justify-content: center;
}

.qrcode {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 5%;
}

.qrcode span {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.12px;

    color: $blue_text;
    margin-top: 5%;
}

.button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 8px;
    flex: 1;

    background: $blue_ipiranga;
    border-radius: 100px;
    border: 1px solid $blue_ipiranga;
}

.buttonClose {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 8px;
    flex: 1;

    background: $white;
    border-radius: 100px;
    border: 1px solid $red_1;
}

.closeTextButton {
    font-family: 'Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;

    color: $red_1;
}

.saveText {
    font-family: 'Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;

    color: $white;
}

.areaClassName {
    width: 100% !important;
    display: flex;
    justify-content: space-around;
}

.footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
