@import '../../../../utils/variables.scss';

.backButton {
    border: 1px solid $blue_ipiranga;
    background: transparent;
    width: fit-content;
    margin-left: auto;
    margin-right: 12px;
}
.backButton span {
    color: $blue_ipiranga;
}

.title {
    font-family: Bold Titulos;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 12px;
    color: $blue_text;
}
