@import "../../utils/variables.scss";

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;
  width: 100%;
  height: 37px;
  min-width: 150px;

  background: $blue_ipiranga;
  border-radius: 100px;
  cursor: pointer;
}

.fileButtonText {
  font-style: normal;
  font-family: Regular;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.05em;
  color: #fff;
}

.loading {
  padding: 12px;
  width: fit-content !important;
  border: 2px solid #fff;
  border-radius: 50%;
  border-top-color: transparent;
  flex-shrink: none;
  animation: rotate 0.5s linear infinite;
}

.rotate {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
