@import '../../utils/variables.scss';

.modal {
    display: flex;
    flex-direction: column;
    // max-width: 960px;
    width: 100%;
}

.modalDialog {
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalContent {
    width: fit-content;
}

.title {
    font-family: Bold Titulos;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 24px;
    color: $blue_text;
}
