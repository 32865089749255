@import '../../utils/variables.scss';

.container {
    padding-bottom: 10%;
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 3%;
    font-family: 'Roboto';
}
