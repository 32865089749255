@import '../../../../utils/variables.scss';

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.spinner {
    width: 200px;
    height: 200px;
    border: 10px solid $blue_ipiranga;
    border-top-color: $grey_2;
    border-radius: 50%;
    animation: spin 1.5s linear infinite;
}
