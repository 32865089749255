@import '../../utils/variables.scss';

.borderTable {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 12px 14px;
    gap: 28px;

    width: 100%;

    border: 1px solid $yellow_ipiranga;
}
.contentHeader {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 2%;
}
.includeBaseButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 8px;

    width: auto;
    height: 37px;

    background: $blue_ipiranga;
    border-radius: 100px;
}

.includeBaseText {
    font-family: 'Medium';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    text-align: center;

    color: $white;
}
.typeahead {
    box-sizing: border-box;

    width: 100% !important;
    height: 40px;

    background: $white;
    border: 1px solid $grey_1;
    border-radius: 1rem !important;
}

th {
    cursor: pointer;
}

.checkbox {
    margin-left: 5%;
}

.checkboxCol {
    display: flex;
    justify-content: center;
}

.trashButton {
    cursor: pointer;
}

.footer {
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 8px 0 8px 0px;
    gap: 20px;

    position: absolute;
    width: 100%;
    height: 60px;
    left: 0px;
    bottom: 0px;

    background: $white;
    border: 1px solid $grey_2;
}

.actionsButtons {
    position: relative;
    width: 35%;
    display: flex;
    justify-content: space-evenly;
    padding-right: 5%;
}
.saveButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 8px;

    width: 129px;
    height: 44px;

    background: $blue_ipiranga;
    border-radius: 100px;
    border: 1px solid $blue_ipiranga;
}

.buttonDisabled {
    background: $grey_2;
    border: 1px solid $grey_2;
}

.saveText {
    font-family: 'Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;

    color: $white;
}

.cancelButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 8px;

    width: 129px;
    height: 44px;

    border: 1px solid $red_1;
    border-radius: 100px;
    background: #fff;
    color: $red_1;
}

.cancelText {
    font-family: 'Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;

    color: $red_1;
}
