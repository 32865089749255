$yellow_ipiranga: #ffd100;
$yellow_secondary: #ffe91a;
$yellow_tertiary: #ffb206;

$white: #fff;
$white_button_text: #e3e3e3;
$input_background: #eef0f2;

$blue_ipiranga: #0045b5;
$blue_text: #00266e;
$blue_secondary: #44e4ff;
$blue_tertiary: #0068ff;
$blue_quaternary: #002572;

$black: #000;

$grey_1: #94949a;
$grey_2: #d1d3d4;

$red_1: #b11b1b;

$green_1: #00551d;
$chumbo: #58595b;
