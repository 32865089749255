@import '../../utils/variables.scss';

.container {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    height: 95vh;
    flex-direction: column;
    background-image: url('../../assets/background.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.footer {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    height: 95vh;
    flex-direction: row;
    height: 5vh;
    background-color: #000000;
    color: white;
    width: 100%;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
}

.subTitle {
    font-size: 28px;
    line-height: 30px;
    font-family: Regular;
}

.title {
    font-family: Bold Titulos;
    font-size: 40px;
    line-height: 48px;
    font-weight: bold;
}

.center {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: white;
    text-align: center;
}

.inputContainer {
    width: 100% !important;
    padding: 0 !important;
}

.inputContainerPassword {
    margin-top: 7.33%;
}

.inputPwd {
    position: relative;
    .badge-visibility {
        position: absolute;
        top: 27%;
        right: 3%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px !important;
        background-color: transparent !important;
        cursor: pointer;
    }

    .input-icon {
        color: red;
        height: 60% !important;
    }
}

.button {
    max-width: 370px;
    font-family: 'Roboto';
}

.secondaryButton {
    background: transparent;
    border: 1px solid #fff;
    color: #fff;
}

.buttonArea {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 15%;
    align-items: center;
    justify-content: center;
}

.joinButton {
    background: $blue_ipiranga;
}

.joinButtonText {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.15px;

    color: $white_button_text;
}

.forgotPasswordArea {
    margin-top: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.forgotPasswordText {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
    text-decoration-line: underline;

    color: $blue_text;
}
