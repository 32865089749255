@import "../../utils/variables.scss";

.rotate {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadingPage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.loading {
  width: 50px;
  height: 50px;
  border: 4px solid $blue_ipiranga;
  border-radius: 50%;
  border-top-color: transparent;
  animation: rotate 0.5s linear infinite;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.loadingButton {
  width: 30px;
  height: 30px;
  border: 4px solid $blue_ipiranga;
  border-radius: 50%;
  margin: 0 25px 0 25px; 
  border-top-color: transparent;
  animation: rotate 0.5s linear infinite;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}


.loaderTable {
  position: absolute;

  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.1);
}
