@import "../../utils/variables.scss";

.title {
  font-family: "Bold Titulos";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.18px;

  color: $blue_text;
  margin: 5% 2% 2%;
}

.header :nth-child(2) {
  position: absolute;
  right: 3%;
  top: 8%;
}

.modal {
  padding-top: 10%;
}

.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.body {
  max-height: 600px;
  overflow-y: auto;
}

.areaClassName {
}
