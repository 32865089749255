@import '../../utils/variables.scss';

.container {
    padding-bottom: 10%;
    font-family: 'Regular' !important;
}

.borderTable {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 12px 14px;
    gap: 28px;

    min-width: 1000px;

    width: 100%;

    border: 1px solid $yellow_ipiranga;
}
.contentHeader {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 2%;
}
.includeBaseButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 8px;

    width: auto;
    height: 37px;

    background: $blue_ipiranga;
    border-radius: 100px;
}

.includeBaseText {
    font-family: 'Medium';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    text-align: center;

    color: $white;
}
.typeahead {
    box-sizing: border-box;

    width: 100% !important;
    height: 40px;

    background: $white;
    border: 1px solid $grey_1;
    border-radius: 1rem !important;
}

th {
    cursor: pointer;
    font-family: Bold Titulos !important;
    color: #00266e !important;
}



td {
    font-family: 'Regular' !important;
    color: #00266e !important;
}


.checkboxHeaders {
  margin-left: 5%;
}

.checkbox {
  margin-left: 25%;
}

.checkboxCol {
    display: flex;
    justify-content: center;
}

.trashButton {
    cursor: pointer;
}

.footer {
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 8px 0 8px 0px;
    gap: 20px;

    position: fixed;
    width: 100%;
    height: 60px;
    left: 0px;
    bottom: 0.5%;

    background: $white;
    border: 1px solid $grey_2;
}

.actionsButtons {
    position: relative;
    width: 40%;
    display: flex;
    justify-content: space-evenly;
    padding-right: 5%;
}
.saveButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 8px;

    width: 129px;
    height: 44px;

    background: $blue_ipiranga;
    border-radius: 100px;
}

.saveText {
    font-family: 'Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;

    color: $white;
}

.cancelButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 8px;

    width: 129px;
    height: 44px;

    border: 1px solid $red_1;
    border-radius: 100px;
}

.cancelText {
    font-family: 'Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;

    color: $red_1;
}

.inputArea {
    display: flex;
    justify-content: flex-start;
    padding-left: 2%;
    height: auto;
}

.inputContainer {
    box-sizing: border-box;

    width: 328px;
    height: 44px;

    background: $white;
}

.inputContainer :nth-child(2) {
    border: 1px solid $grey_2;
    border-radius: 100px !important;
}

.loadBaseInput {
    margin-left: 20px !important;
}

.radioGroup {
    display: flex;
    justify-content: flex-start;
    gap: 2px;
    margin: 5% 0;
    padding-left: 2%;
    width: 100%;
}

.span {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
}

.radioDiv {
    gap: 5px;
    margin: 0 6px;
}

.modalCustomClass {
    width: 50%;
    margin-left: 25%;
}

.loading {
    width: 50px;
    height: 50px;
    border: 4px solid $blue_ipiranga;
    border-radius: 50%;
    border-top-color: transparent;
    animation: rotate 0.5s linear infinite;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
}

.rotate {
    animation: rotate 2s linear infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loadingPage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}
