@import '../../../../utils/variables.scss';

.title {
    font-family: Bold Titulos;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 24px;
    color: $blue_text;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    max-width: 470px;
}

.containerButtons {
    margin-left: auto;
    padding: 0;
    width: fit-content;
    margin-top: 32px;
    gap: 20px;
}

.cancelButton {
    border-color: $blue_ipiranga;
}

.cancelButton span {
    color: $blue_ipiranga;
}
