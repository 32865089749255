@import "../../utils/variables.scss";

.rotate {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading {
  width: 50px;
  height: 50px;
  border: 4px solid $blue_ipiranga;
  border-radius: 50%;
  border-top-color: transparent;
  animation: rotate 0.5s linear infinite;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

