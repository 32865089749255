@import '../../utils/variables.scss';

.container {
    display: flex;
    justify-content: flex-start !important;
    flex-direction: column;
    position: relative;
    height: 100vh;
}

.logoImg {
    height: 36px;
    width: auto;
    margin-right: 4px;
}

.logoTitle {
    font-family: Bold Titulos;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: $blue_ipiranga;
    display: flex;
    align-items: flex-end;
    line-height: 22px;
}

.headerArea {
    margin-top: 5px;
}
.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0% 10%;
    padding-top: 12px;
}
.headerContent {
    width: 50% !important;
}
.flex {
    display: flex;
}

.optionArea {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px !important;
    background-color: #00000020;
}

.profileText {
    margin-left: 16px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;

    color: $black;
}

.marginLeft {
    margin-left: 25px;
}

.profileArea {
    background-color: transparent !important;
    border: none !important;
}
.profileArea:after {
    display: none;
    background-color: transparent !important;
}

.dropdown > .dropdown-menu {
    min-width: fit-content;
}

.breadcrumbArea {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 0% 10%;
}

.titlePageArea {
    position: relative;
    display: flex;
    width: 100%;
    font-family: 'Roboto';
    margin: 2% 0px;
    height: 100% !important;
}

.titlePage {
    font-family: Bold Titulos;
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 36px;
    color: $blue_text !important;
    margin-left: 8%;
}

.backButton {
    position: absolute;
    left: 2%;
    top: 8.33%;

    background: $white;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12),
        0px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    height: 30px;
    width: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.descriptionArea {
    margin-bottom: 1.5%;
}
.description {
    font-family: 'Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;
    color: $blue_text;
}

.containerDropdown {
    background-color: #00000012;
    // gap: 10px;
    // padding: 8px, 16px, 8px, 8px;\
    padding: 8px;
    box-shadow: 0px 2px 4px 0px #00000033;

    box-shadow: 0px 1px 10px 0px #0000001f;

    box-shadow: 0px 4px 5px 0px #00000024;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 12px;
}

@media only screen and (max-width: 768px) {
    .headerContent {
        width: 80% !important;
    }
    .searchIcon {
        position: absolute;
        top: 22%;
        right: 25%;
    }
}

.spacing {
    height: 20px;
}
