@import "../../utils/variables.scss";

.title {
  font-family: Bold Titulos;
  font-style: normal;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.18000000715255737px;
  text-align: left;
  color: $blue_text;
}

.actionsButtons {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  width: 40%;
}

.buttonPDF {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;
  flex: 1;

  height: 44px;

  background: $blue_ipiranga;
  border-radius: 100px;
  border: 1px solid $blue_ipiranga;
}

.buttonCSV {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;
  flex: 1;

  height: 44px;

  border: 1px solid $blue_ipiranga;
  border-radius: 100px;
  background: #fff;
  color: $blue_ipiranga;
}

.textPDF {
  font-family: "Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: $white;
}

.csvLink {
  font-family: "Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: $blue_ipiranga;
  text-decoration: none;
}
