@import "../../utils/variables.scss";

.routeArea {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.text-crumb {
  font-family: "Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;

  color: $blue_text;
}
